<template>
  <div>
    <div class="h4">I have subscribed on all Liquifi's social media</div>

    <!-- <SocialMediaLinks class="mt-3" /> -->

    <b-form-checkbox class="mt-3" v-model="selected" name="ready">
      Ready
    </b-form-checkbox>

    <b-button
        block
        variant="primary"
        class="button-flat mt-3"
        v-on:click="onNextStep"
        :disabled="!selected"
    >Next</b-button>
  </div>
</template>

<script>
export default {
  name: 'SubscribeStep',
  components: {
    // SocialMediaLinks: () => import('@/view/pages/bind/preRegisterStepper/components/SocialMediaLinks'),
  },

  data: function () {
    return {
      selected: false,
    };
  },

  methods: {
    onNextStep() {
      this.$emit('next');
    },
  }
};
</script>

<style scoped lang="scss">

</style>
